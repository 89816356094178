import { DetailedHTMLProps, HTMLAttributes } from 'react';
import styles from './SmallTextPost.module.scss';

const SmallTextPost = ({
  className = '',
  children,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>) => (
  <p className={`${styles.main} ${className}`} {...props}>
    {children}
  </p>
);

export default SmallTextPost;
