import React, { useEffect, useRef, useState } from 'react';
import { getPhotoSource, getVideoSource } from '../helpers/axios';
import eventEmitter from '../helpers/eventEmitter';
import MainTextBold from './Fonts/MainTextBold';
import SmallTextPost from './Fonts/SmallTextPost';
import FormattedText from './FormattedText';
import styles from './Post.module.scss';
import ProgressiveImage from './ProgressiveImage';
import VideoViewer from './VideoViewer';
import LikeIcon from '../icons/like/px20.svg';
import LikeFilledIcon from '../icons/like-filled/px20.svg';
import ArrowDownIcon from '../icons/arrow/down.svg';
import Caption from './Fonts/Caption';
import formatDate from '../helpers/formatDate';
import ReactionButton from './ReactionButton';
import PostCommentsBlock from './PostCommentsBlock';
import UserPost from '../types/UserPost';

const CarouselCounter = ({ index, total }: { index: number; total: number }) => {
  return (
    <div className={styles.carouselCounter}>
      <SmallTextPost style={{ color: '#FFFFFF', textAlign: 'center' }}>
        {`${index + 1}/${total}`}
      </SmallTextPost>
    </div>
  );
};

const Post = ({ post, width }: { post: Partial<UserPost>; width?: number }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentIndexRef = useRef(0);
  const playingVideo = useRef(getVideoSource(post.contents[0].path));
  const scrollRef = useRef<HTMLDivElement>(null);
  const [bodyOpen, setBodyOpen] = useState(false);
  const [bodyRealHeight, setBodyRealHeight] = useState(100);
  const textRef = useRef<HTMLButtonElement>();

  useEffect(() => {
    const ref = scrollRef.current;

    const scrollListener = e => {
      const lwidth = Math.min(window.innerWidth, 500, width || 500);
      const newIndex = Math.floor((e.target.scrollLeft + lwidth / 2) / lwidth);
      if (newIndex !== currentIndexRef.current) {
        const currentSource = getVideoSource(post.contents[currentIndexRef.current].path);
        const newSource = getVideoSource(post.contents[newIndex].path);
        currentIndexRef.current = newIndex;

        eventEmitter.emit(`pauseVideo${currentSource}`);
        if (post.contents[newIndex].type === 'video') {
          playingVideo.current = newSource;
          console.log(`playVideo${newSource}`);
          eventEmitter.emit(`playVideo${newSource}`);
        } else {
          playingVideo.current = '';
        }
        setCurrentIndex(newIndex);
      }
    };

    const newSource = getVideoSource(post.contents[0].path);
    currentIndexRef.current = 0;
    if (post.contents[0].type === 'video') {
      playingVideo.current = newSource;
      eventEmitter.emit(`playVideo${newSource}`);
    } else {
      playingVideo.current = '';
    }

    ref?.addEventListener('scroll', scrollListener, { passive: true });

    if (textRef.current) {
      setBodyRealHeight(textRef.current.scrollHeight);
      if (textRef.current.scrollHeight <= 120) {
        setBodyOpen(true);
      }
    }

    return () => ref?.removeEventListener('scroll', scrollListener);
  }, [post.contents, width]);

  return (
    <div className={styles.container}>
      <div className={styles.postHead}>
        <ProgressiveImage
          containerClassName={styles.avatar}
          height={25}
          width={25}
          thumbnailUri={post.account.avatarThumbnail}
          src={getPhotoSource(post.account.avatar)}
        />
        <MainTextBold className={styles.username}>{post.account?.username}</MainTextBold>
      </div>
      <div
        className={styles.assetsContainer}
        style={{
          height: `min(calc(100vh - 50px), ${
            width
              ? `${width / (post.format || 3 / 4)}px`
              : `calc(min(100vw, 500px) / ${post.format || 3 / 4})`
          })`,
        }}
      >
        <div ref={scrollRef} className={styles.assets}>
          {post.contents.map((content, index) =>
            content.type === 'video' ? (
              <VideoViewer
                thumbnailUri={content.thumbnail}
                src={getVideoSource(content.path)}
                className={styles.asset}
                preload={currentIndex === index ? 'auto' : 'none'}
                previewSrc={content.preview ? getPhotoSource(content.preview) : ''}
              />
            ) : (
              <ProgressiveImage
                thumbnailUri={content.thumbnail}
                src={getPhotoSource(content.path)}
                containerClassName={styles.asset}
              />
            ),
          )}
          <div className={styles.paddingFix} />
        </div>
        {post.contents.length > 1 && (
          <div className={styles.carouselCounterContainer} style={width ? { width } : {}}>
            <CarouselCounter index={currentIndex} total={post.contents.length} />
          </div>
        )}
      </div>
      {post.description ? (
        <>
          <button
            ref={textRef}
            className={styles.descriptionContainer}
            style={{ maxHeight: bodyOpen ? bodyRealHeight : 100 }}
            onClick={() => {
              setBodyOpen(true);
            }}
          >
            <SmallTextPost className={styles.description}>
              <FormattedText text={post.description} />
            </SmallTextPost>
            <div className={styles.gradient} style={{ height: bodyOpen ? 0 : 100 }} />
          </button>
          <button
            className={styles.openBody}
            style={{ maxHeight: bodyOpen ? 0 : 20 }}
            onClick={() => {
              setBodyOpen(true);
            }}
          >
            <ArrowDownIcon style={{ zIndex: 1 }} color={styles.TEXT} />
            <div
              className={styles.readBody}
              style={{ width: `${bodyOpen ? 100 : (100 / bodyRealHeight) * 100}%` }}
            />
          </button>
        </>
      ) : null}
      <div className={styles.actionsContainer}>
        <div className={styles.actions}>
          <ReactionButton
            reacted={post.liked}
            reactionNumber={post.likeNumber}
            onLongPress={() => console.log('longPress')}
            onClick={() => console.log('click')}
            reactedIcon={<LikeFilledIcon color={styles.RED_COLOR} />}
            notReactedIcon={<LikeIcon color={styles.TEXT} />}
          />
        </div>
        <Caption style={{ color: styles.TEXT_GRAY }}>{formatDate(post.createdAt)}</Caption>
      </div>
      <PostCommentsBlock post={post} />
    </div>
  );
};

export default Post;
