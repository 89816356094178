import { captureException } from '@sentry/nextjs';

class TextItem {
  public type: 'text' | 'username' | 'hashtag' | 'url';

  public value: string;

  public key: string;
}

const urlRegex =
  /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/g;
const usernameRegex = /@[a-zA-Z0-9_.]+/g;
const hashtagRegex = /#[a-zA-ZА-Яа-я0-9ёЁ_]+/g;

type RegexItem = {
  index: number;
  type: TextItem['type'];
  value: string;
};

const formatText = (text: string): TextItem[] => {
  if (typeof text !== 'string') {
    return [{ type: 'text', value: text, key: '1' }];
  }

  try {
    const urls: RegexItem[] = Array.from(text.matchAll(urlRegex)).map(item => ({
      index: item.index,
      type: 'url',
      value: item.toString(),
    }));

    const usernames: RegexItem[] = Array.from(text.matchAll(usernameRegex)).map(item => ({
      index: item.index,
      type: 'username',
      value: item.toString(),
    }));

    const hashtags: RegexItem[] = Array.from(text.matchAll(hashtagRegex)).map(item => ({
      index: item.index,
      type: 'hashtag',
      value: item.toString(),
    }));

    const items = [...urls, ...usernames, ...hashtags].sort((a, b) => a.index - b.index);

    if (items.length === 0) {
      return [{ type: 'text', value: text, key: '1' }];
    }

    const formattedDescription: TextItem[] = [];

    items.forEach((item, index) => {
      if (index === 0) {
        if (item.index !== 0) {
          formattedDescription.push({
            type: 'text',
            value: text.substring(0, item.index),
            key: Math.random().toFixed(10),
          });
        }
      } else {
        const previousItem = items[index - 1];
        if (item.index - previousItem.index - previousItem.value.length !== 0) {
          formattedDescription.push({
            type: 'text',
            value: text.substring(previousItem.index + previousItem.value.length, item.index),
            key: Math.random().toFixed(10),
          });
        }
      }
      formattedDescription.push({
        type: item.type,
        value: item.value,
        key: Math.random().toFixed(10),
      });
      if (index === items.length - 1 && item.index + item.value.length !== text.length) {
        formattedDescription.push({
          type: 'text',
          value: text.substring(item.index + item.value.length, text.length),
          key: Math.random().toFixed(10),
        });
      }
    });

    return formattedDescription;
  } catch (e) {
    captureException(e);
  }
  return [{ type: 'text', value: text, key: '1' }];
};

export default formatText;
