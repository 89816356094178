import React, { useEffect, useRef, useState } from 'react';
import styles from './ProgressiveImage.module.scss';

interface ProgressiveImageProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  thumbnailUri: string;
  src: string;
  className?: string;
  containerClassName?: string;
  width?: string | number;
  height?: string | number;
}

const ProgressiveImage = ({
  thumbnailUri = '',
  src = '',
  className = '',
  width,
  height,
  containerClassName = '',
  ...props
}: ProgressiveImageProps) => {
  const [loaded, setLoaded] = useState(false);
  const imageRef = useRef<HTMLImageElement>();

  useEffect(() => {
    const listener = () => {
      setLoaded(true);
    };
    const ref = imageRef.current;
    ref.src = '';
    ref.addEventListener('load', listener);
    ref.src = src;

    return () => ref.removeEventListener('load', listener);
  }, [src]);

  return (
    <div
      style={{ ...(height && { height }), ...(width && { width }) }}
      className={`${styles.container} ${containerClassName}`}
    >
      <img {...props} src={thumbnailUri} className={`${styles.thumbnail} ${className}`} />
      <img
        {...props}
        ref={imageRef}
        className={`${styles.image} ${loaded ? styles.loaded : ''} ${className}`}
      />
    </div>
  );
};

export default ProgressiveImage;
