import React, {
  DetailedHTMLProps,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  VideoHTMLAttributes,
} from 'react';
import eventEmitter from '../helpers/eventEmitter';

export interface VideoProps
  extends DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> {
  onPlay?: () => void;
  onPause?: () => void;
  paused?: boolean;
}

const defaultProps = {
  onPlay: () => {},
  onPause: () => {},
  paused: true,
};

export interface VideoRef {
  onPlay: () => void;
  onPause: () => void;
  seek: (time: number) => void;
}

const Video = forwardRef<VideoRef, VideoProps>(
  ({ src, paused: initialPaused, onPlay: onPlayEvent, onPause: onPauseEvent, ...props }, ref) => {
    const [paused, setPaused] = useState(initialPaused);
    const videoRef = useRef<HTMLVideoElement>(null);

    function onPlay(): void {
      setPaused(false);
      onPlayEvent?.();
    }

    function onPause(): void {
      setPaused(true);
      onPauseEvent?.();
    }

    useImperativeHandle(ref, () => ({
      seek: (time: number) => {
        if (videoRef.current) {
          videoRef.current.currentTime = time;
        }
      },
      onPlay,
      onPause,
    }));

    useEffect(() => {
      setPaused(initialPaused);
    }, [initialPaused]);

    useEffect(() => {
      eventEmitter.addListener(`playVideo${src}`, onPlay);
      eventEmitter.addListener(`pauseVideo${src}`, onPause);

      return () => {
        eventEmitter.removeListener(`playVideo${src}`, onPlay);
        eventEmitter.removeListener(`pauseVideo${src}`, onPause);
      };
    }, [src]);

    useEffect(() => {
      if (paused) {
        videoRef.current?.pause();
      } else {
        videoRef.current?.play();
      }
    }, [paused]);

    return <video ref={videoRef} src={src} {...props} />;
  },
);

Video.displayName = 'Video';
Video.defaultProps = defaultProps;

export default React.memo(Video);
