import React, { useMemo } from 'react';
import formatText from '../helpers/formatDescription';
import BlueSpan from './Fonts/BlueSpan';

const FormattedText = ({ text, disabled = false }: { text: string; disabled?: boolean }) => {
  const formatted = useMemo(() => formatText(text), [text]);

  return (
    <>
      {formatted.map(item => {
        if (item.type === 'text') {
          return (
            <React.Fragment key={item.key}>
              {item.value.split('\n').map((part, index) => (
                <>
                  {index ? <br /> : null}
                  {part}
                </>
              ))}
            </React.Fragment>
          );
        }
        if (['hashtag', 'username'].includes(item.type)) {
          return (
            <BlueSpan
              key={item.key}
              onClick={
                disabled
                  ? undefined
                  : () => {
                      console.log('hashtag/username');
                    }
              }
            >
              {item.value}
            </BlueSpan>
          );
        }
        if (item.type === 'url') {
          return (
            <a key={item.key} href={item.value} rel="nofollow">
              <BlueSpan>{item.value}</BlueSpan>
            </a>
          );
        }

        return null;
      })}
    </>
  );
};

export default FormattedText;
