import React from 'react';
import styles from './ReactionButton.module.scss';
import SmallTextPost from './Fonts/SmallTextPost';

const ReactionButton = ({
  reacted,
  reactedIcon,
  notReactedIcon,
  onClick,
  onLongPress,
  reactionNumber,
}) => {
  let Icon = reactedIcon;

  if (notReactedIcon && !reacted) {
    Icon = notReactedIcon;
  }

  return (
    <button
      className={styles.main}
      style={{ backgroundColor: reacted ? styles.BLUE : styles.BACKGROUND_3 }}
      // @ts-expect-error: TODO
      onLongPress={onLongPress}
      onClick={onClick}
    >
      {Icon}
      <SmallTextPost style={{ marginLeft: 7, color: reacted ? styles.TEXT_WHITE : styles.TEXT }}>
        {reactionNumber}
      </SmallTextPost>
    </button>
  );
};

export default ReactionButton;
