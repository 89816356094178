const formatSeconds = (secondsTime: number, forceHours: boolean = false) => {
  const secondsPosition = secondsTime;
  const seconds1 = Math.floor((secondsPosition % 60) / 10);
  const seconds2 = Math.floor(secondsPosition % 10);
  const minutesPosition = Math.floor(secondsPosition / 60);
  const minutes1 = Math.floor((minutesPosition % 60) / 10);
  const minutes2 = Math.floor(minutesPosition % 10);
  const hoursPosition = Math.floor(secondsPosition / 3600);

  return `${forceHours || hoursPosition ? `${hoursPosition}:` : ''}${
    forceHours || hoursPosition || minutes1 ? minutes1 : ''
  }${minutes2}:${seconds1}${seconds2}`;
};

export default formatSeconds;
