import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import PlayVideo from '../icons/play/px40.svg';
import PauseVideo from '../icons/pause/px40.svg';
import Muted from '../icons/muted.svg';
import Unmuted from '../icons/unmuted.svg';
import Pause from '../icons/pause/widget.svg';
import styles from './VideoViewer.module.scss';
import Spinner from './Spinner';
import videoContext from '../helpers/videoContext';
import Video, { VideoRef } from './Video';
import SmallTextPost from './Fonts/SmallTextPost';
import formatSeconds from '../helpers/formatSeconds';

interface VideoViewerProps {
  src: HTMLVideoElement['src'];
  previewSrc?: HTMLImageElement['src'];
  videoClassName?: HTMLVideoElement['className'];
  onClick?: HTMLDivElement['onclick'];
  thumbnailUri?: string;
  className?: HTMLDivElement['className'];
  width?: string | number;
  height?: string | number;
  preload?: HTMLVideoElement['preload'];
}

export type VideoState = 'playing' | 'paused' | 'reset' | 'loading' | 'ready';
type OverlayButtonType = 'play' | 'pause' | 'restart';

const VideoViewer: FC<VideoViewerProps> = ({
  src,
  previewSrc = '',
  videoClassName,
  onClick,
  thumbnailUri,
  className,
  width,
  height,
  preload = 'auto',
}) => {
  const { muted, setMuted } = useContext(videoContext);
  const [paused, setPaused] = useState(true);
  const [isLoaded, setIsLoaded] = useState<boolean>();
  const [videoProgress, setVideoProgress] = useState('0:00');
  const [overlayType, setOverlayType] = useState<OverlayButtonType>('play');
  const [overlayShown, setOverlayShown] = useState(false);
  const [preloaded, setPreloaded] = useState(false);
  const [previewLoaded, setPreviewLoaded] = useState(false);
  const previewRef = useRef<HTMLImageElement>();
  const pastRef = useRef<HTMLDivElement>();
  const thumbRef = useRef<HTMLDivElement>();
  const duration = useRef<number>();
  const grabbing = useRef<boolean>(false);
  const wasPaused = useRef<boolean>(false);
  const grabPosition = useRef<number>(0);
  const videoRef = useRef<VideoRef>();

  useEffect(() => {
    if (previewSrc) {
      const listener = () => {
        setPreviewLoaded(true);
      };
      const ref = previewRef.current;
      ref.addEventListener('load', listener);
      ref.src = previewSrc;

      return () => ref.removeEventListener('load', listener);
    }
  }, [previewSrc]);

  function getVideoControllerView() {
    switch (overlayType) {
      case 'play': {
        return (
          <div
            style={{
              height: 60,
              width: 60,
              alignItems: 'center',
              pointerEvents: 'none',
              touchAction: 'none',
            }}
          >
            <PlayVideo />
          </div>
        );
      }
      case 'pause': {
        return (
          <div
            style={{
              height: 60,
              width: 60,
              alignItems: 'center',
              pointerEvents: 'none',
              touchAction: 'none',
            }}
          >
            <PauseVideo />
          </div>
        );
      }
      default:
        return null;
    }
  }

  useEffect(() => {
    setOverlayShown(true);
    if (paused) {
      setOverlayType('pause');
    } else {
      setOverlayType('play');
    }
    const timeout = setTimeout(() => {
      setOverlayShown(false);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [paused]);

  return (
    <div
      style={{ ...(height && { height }), ...(width && { width }) }}
      className={`${styles.container} ${className}`}
    >
      <img src={thumbnailUri} className={styles.thumbnail} />
      {previewSrc ? (
        <img
          ref={previewRef}
          className={`${styles.image} ${previewLoaded ? styles.visible : ''} ${className}`}
        />
      ) : null}
      <button
        onClick={e => {
          // @ts-expect-error: ругается на this
          onClick?.(e);
          setPaused(!paused);
        }}
        className={styles.videoButton}
      >
        <Video
          ref={videoRef}
          src={src}
          muted={muted}
          loop
          paused={paused}
          className={`${styles.videoContainer} ${videoClassName}`}
          onLoadedData={() => setPreloaded(true)}
          onCanPlay={() => setIsLoaded(true)}
          onCanPlayThrough={() => setIsLoaded(true)}
          onWaiting={() => setIsLoaded(false)}
          onPlay={() => setPaused(false)}
          onPause={() => setPaused(true)}
          onTimeUpdate={e => {
            // @ts-expect-error: проблема с типом target, у видео есть duration и currentTime
            const past = (e.target.currentTime / e.target.duration) * 100;

            if (thumbRef.current) {
              thumbRef.current.style.transform = `translate(${past}%)`;
            }
            if (pastRef.current) {
              pastRef.current.style.transform = `translate(${past - 100}%)`;
            }

            // @ts-expect-error: проблема с типом target, у видео есть duration и currentTime
            duration.current = e.target.duration;

            // @ts-expect-error: проблема с типом target, у видео есть duration и currentTime
            const secondsLeft = Math.floor(e.target.duration - e.target.currentTime);
            // @ts-expect-error: проблема с типом target, у видео есть duration и currentTime
            const durationSeconds = Math.floor(e.target.duration);

            setVideoProgress(formatSeconds(secondsLeft || 0, durationSeconds >= 3600));
          }}
          preload={preload}
        />
      </button>
      <div className={`${styles.controlsContainer} ${paused ? styles.paused : ''}`}>
        <div className={styles.controls}>
          <button className={styles.controlElement} onClick={() => setMuted(!muted)}>
            <SmallTextPost style={{ color: 'white', marginRight: 5 }}>
              {videoProgress}
            </SmallTextPost>
            {isLoaded && !paused && muted && <Muted />}
            {isLoaded && !paused && !muted && <Unmuted />}
            {paused && <Pause />}
            {!paused && !isLoaded && <Spinner size="13" color="white" />}
          </button>
        </div>
        <div
          className={styles.progressBar}
          onPointerDown={e => {
            e.preventDefault();
            e.persist();
            grabbing.current = true;
            // @ts-expect-error: проблема с типами
            const past = (e.offsetX / e.target.offsetWidth) * 100;

            wasPaused.current = paused;
            setPaused(true);
            grabPosition.current = past;

            if (thumbRef.current) {
              thumbRef.current.style.transition = `none`;
              thumbRef.current.style.transform = `translate(${past}%)`;
            }
            if (pastRef.current) {
              pastRef.current.style.transition = `none`;
              pastRef.current.style.transform = `translate(${past - 100}%)`;
            }
          }}
          onPointerMove={e => {
            if (grabbing.current) {
              // @ts-expect-error: проблема с типами
              const past = (e.offsetX / e.target.offsetWidth) * 100;

              grabPosition.current = past;

              if (thumbRef.current) {
                thumbRef.current.style.transform = `translate(${past}%)`;
              }
              if (pastRef.current) {
                pastRef.current.style.transform = `translate(${past - 100}%)`;
              }
            }
          }}
          onPointerUp={() => {
            setPaused(wasPaused.current);
            grabbing.current = false;
            if (thumbRef.current) {
              thumbRef.current.style.transition = ``;
            }
            if (pastRef.current) {
              pastRef.current.style.transition = ``;
            }
            videoRef.current.seek((duration.current * grabPosition.current) / 100);
          }}
        >
          <div className={styles.thumbContainer} ref={thumbRef}>
            <div className={styles.thumb} />
          </div>
          <div className={styles.all}>
            <div className={styles.past} ref={pastRef} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(VideoViewer);
