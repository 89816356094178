import React from 'react';
import declinationOfWords from '../helpers/declinationOfWords';
import SmallTextPost from './Fonts/SmallTextPost';
import ArrowRightIcon from '../icons/arrow/right.svg';
import CommentIcon from '../icons/comment/px20.svg';
import ProgressiveImage from './ProgressiveImage';
import { getPhotoSource } from '../helpers/axios';
import styles from './PostCommentsBlock.module.scss';

const PostCommentsBlock = ({ post }) => {
  const lastComment = post.comments ? post.comments[post.comments.length - 1] : null;

  return (
    <button
      className={styles.main}
      onClick={() => {
        console.log('comments');
      }}
    >
      {lastComment ? (
        <ProgressiveImage
          containerClassName={styles.avatar}
          height={25}
          width={25}
          thumbnailUri={lastComment.account.avatarThumbnail}
          src={getPhotoSource(lastComment.account.avatar)}
        />
      ) : (
        <CommentIcon
          width={20}
          height={20}
          color={styles.TEXT}
          style={{ margin: 2.5, marginRight: 12.5 }}
        />
      )}
      <SmallTextPost style={{ flex: 1 }}>
        {post.totalComments
          ? `${post.totalComments} ${declinationOfWords(post.totalComments, [
              'комментарий',
              'комментария',
              'комментариев',
            ])}`
          : 'Прокомментировать'}
      </SmallTextPost>
      <ArrowRightIcon className={styles.arrowRight} height={15} width={15} color={styles.TEXT} />
    </button>
  );
};

export default PostCommentsBlock;
