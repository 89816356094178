import dayjs from 'dayjs';
import declinationOfWords from './declinationOfWords';

export const formatActionDate = (date: string | Date): string => {
  let parsed = Number(date);
  if (typeof date === 'string') {
    parsed = Date.parse(date);
  }

  if (Number.isNaN(parsed)) {
    return '';
  }

  const difference = dayjs().unix() - dayjs(parsed).startOf('day').unix();

  if (difference < 86400) {
    return 'Сегодня';
  }

  if (difference < 172800) {
    return 'Вчера';
  }

  if (difference < 2592000) {
    return `${Math.trunc(difference / 86400)} ${declinationOfWords(Math.trunc(difference / 86400), [
      'день назад',
      'дня назад',
      'дней назад',
    ])}`;
  }

  if (difference < 31104000) {
    return `${Math.trunc(difference / 2592000)} ${declinationOfWords(
      Math.trunc(difference / 86400),
      ['месяц назад', 'месяца назад', 'месяцев назад'],
    )}`;
  }

  return `${Math.trunc(difference / 31104000)} ${declinationOfWords(
    Math.trunc(difference / 31104000),
    ['год', 'года', 'лет'],
  )}`;
};

export const formatShortDate = (date: string | Date): string => {
  let parsed = Number(date);
  if (typeof date === 'string') {
    parsed = Date.parse(date);
  }

  if (Number.isNaN(parsed)) {
    return '';
  }

  const difference = Math.trunc((Number(new Date()) - parsed) / 1000);

  if (difference <= 0) {
    return 'Только что';
  }

  if (difference < 60) {
    return `${difference} сек`;
  }

  if (difference < 3600) {
    return `${Math.trunc(difference / 60)} мин`;
  }

  if (difference < 86400) {
    return `${Math.trunc(difference / 3600)} ч`;
  }

  if (difference < 2592000) {
    return `${Math.trunc(difference / 86400)} д`;
  }

  if (difference < 31104000) {
    return `${Math.trunc(difference / 2592000)} м`;
  }

  return `${Math.trunc(difference / 31104000)} ${declinationOfWords(
    Math.trunc(difference / 31104000),
    ['год', 'года', 'лет'],
  )}`;
};

const formatDate = (date: string | Date): string => {
  let parsed = Number(date);
  if (typeof date === 'string') {
    parsed = Date.parse(date);
  }

  if (Number.isNaN(parsed)) {
    return '';
  }

  const difference = Math.trunc((Number(new Date()) - parsed) / 1000);

  if (difference <= 0) {
    return 'Только что';
  }

  if (difference < 60) {
    return `${difference} ${declinationOfWords(difference, [
      'секунда назад',
      'секунды назад',
      'секунд назад',
    ])}`;
  }

  if (difference < 3600) {
    return `${Math.trunc(difference / 60)} ${declinationOfWords(Math.trunc(difference / 60), [
      'минута назад',
      'минуты назад',
      'минут назад',
    ])}`;
  }

  if (difference < 86400) {
    return `${Math.trunc(difference / 3600)} ${declinationOfWords(Math.trunc(difference / 3600), [
      'час назад',
      'часа назад',
      'часов назад',
    ])}`;
  }

  if (difference < 2592000) {
    return `${Math.trunc(difference / 86400)} ${declinationOfWords(Math.trunc(difference / 86400), [
      'день назад',
      'дня назад',
      'дней назад',
    ])}`;
  }

  if (difference < 31104000) {
    return `${Math.trunc(difference / 2592000)} ${declinationOfWords(
      Math.trunc(difference / 2592000),
      ['месяц назад', 'месяца назад', 'месяцев назад'],
    )}`;
  }

  return `${Math.trunc(difference / 31104000)} ${declinationOfWords(
    Math.trunc(difference / 31104000),
    ['год назад', 'года назад', 'лет назад'],
  )}`;
};

export default formatDate;
