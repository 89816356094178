import { DetailedHTMLProps, HTMLAttributes } from 'react';
import styles from './Spinner.module.scss';

interface SpinnerProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  size?: string;
  color?: string;
}

const Spinner = ({ size = '20', color = '#042D48', ...props }: SpinnerProps) => {
  return (
    <div {...props}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.main}
      >
        <path
          d="M10.0001 6.10352e-05C15.5229 6.10352e-05 20.0001 4.47721 20.0001 10.0001C20.0001 10.5523 19.5524 11 19.0001 11C18.4478 11 18.0001 10.5523 18.0001 10.0001C18.0001 5.58178 14.4183 2.00006 10.0001 2.00006C7.4737 2.00006 5.46624 3.17111 4.00012 5H6.50006C7.05235 5 7.50006 5.44772 7.50006 6C7.50006 6.55228 7.05235 7 6.50006 7H2.00009C1.44781 7 1.00009 6.55228 1.00009 6L1 1.5C1 0.947715 1.44772 0.5 2 0.5C2.55228 0.5 3 0.947715 3 1.5L3.00009 2.99994C4.80384 1.23171 7.27462 6.10352e-05 10.0001 6.10352e-05Z"
          fill={color}
        />
        <path
          d="M10 20.0001C4.47715 20.0001 0 15.523 0 10.0001C0 9.44784 0.447746 9.00006 1.00003 9.00006C1.55232 9.00006 2 9.44784 2 10.0001C2 14.4184 5.58172 18.0001 10 18.0001C12.5264 18.0001 14.5339 16.829 16.0001 15.0001L13.5001 15C12.9478 15 12.5001 14.5523 12.5001 14C12.5001 13.4477 12.9478 13 13.5001 13L18 13.0001C18.5523 13.0001 19 13.4478 19 14.0001V18.5C19 19.0523 18.5523 19.5 18 19.5C17.4477 19.5 17 19.0523 17 18.5V17C15.1963 18.7683 12.7255 20.0001 10 20.0001Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default Spinner;
