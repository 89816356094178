import Image from 'next/image';
import MainText from './Fonts/MainText';
import styles from './DownloadBlock.module.scss';
import TelegramIcon from './TelegramIcon';
import VKIcon from '../icons/vk.svg';

const DownloadBlock = ({ center = false }) => {
  return (
    <div>
      <MainText className={`${styles.mainText} ${center ? styles.center : ''}`}>
        Если у вас ещё нет приложения
      </MainText>
      <div className={styles.storesContainer}>
        <div className={`${styles.stores} ${center ? styles.center : ''}`}>
          <a rel="nofollow" href="https://play.google.com/store/apps/details?id=com.cheburgram">
            <Image width={168} height={50} alt="Доступно в Google Play" src="/googleplay.svg" />
          </a>
          <a rel="nofollow" href="https://apps.apple.com/ru/app/rekee/id6444910425">
            <Image width={150} height={50} alt="Загрузите в Google Play" src="/appstore.svg" />
          </a>
          <a rel="nofollow" href="https://url.cloud.huawei.com/jmREQxSjug?shareTo=qrcode">
            <Image width={165} height={50} alt="Откройте в AppGallery" src="/appgallery.png" />
          </a>
          <a rel="nofollow" href="https://apps.rustore.ru/app/com.cheburgram">
            <Image width={150} height={50} alt="Скачайте из RuStore" src="/rustore.svg" />
          </a>
        </div>
      </div>
      <MainText className={`${styles.mainText} ${center ? styles.center : ''}`}>
        Наши социальные сети
      </MainText>
      <div className={`${styles.socials} ${center ? styles.center : ''}`}>
        <a href="https://t.me/rekeeru_social" rel="nofollow">
          <TelegramIcon size={60} />
        </a>
        <a href="https://vk.com/rekeeru_social" rel="nofollow">
          <VKIcon />
        </a>
      </div>
    </div>
  );
};

export default DownloadBlock;
