import { DetailedHTMLProps, HTMLAttributes } from 'react';
import styles from './BlueSpan.module.scss';

const BlueSpan = ({
  className = '',
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>) => (
  <p className={`${styles.main} ${className}`} {...props} />
);

export default BlueSpan;
